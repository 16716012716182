  // # There are two ways to think about a chat id:
  // # - It is "something we can talk about". I.e. a message or a poll where we add messages to it, to talk about it.
  // # - It is "somewhere we can add stuff". If there is not a specific resource we're talking about, the chat id is the space where we add content. This is the oase or one-on-one chat rooms.
  // #
  // # So another way to look at it is, that the chat id signifies the __context__ that an exchange of content happens within.
  // #
  // # At the same time, a chat id is a __resource id__. When we use it as such, we will call it a resource id
  // # and this always points to an actual resource.
  // #
  // # A chat id potentially has several parts:
  // # - An audience
  // # - A path to a resource (with potential sub resources)
  // #
  // # The audience is an oase ID and potentially a sorted list of participant IDs seperated by a colon.
  // # The audience is always the first part of the chat id.
  // # Examples:
  // # - "xyz": A chat with the whole oase xyz
  // # - "xyz:a:b": A chat between a and b in the oase xyz. NOT VISIBLE TO OTHER PARTICIPANTS.
  // #
  // # A resource path is a URL path with the following format:
  // # - /m/:message_id
  // # - /m/:message_id/i/:image_id
  // #
  // # Allowed resources:
  // # m: A message
  // # i: An image
  // # p: A poll
  // # e: An event
  // # x: An expense
  // #
  // # Together, the audience and the resource path form a chat id.:
  // # "xyz": A chat with the whole oase xyz
  // # "xyz/m/123": A chat with the whole oase xyz, centered around the message 123
  // # "xyz/m/123/i/456": A chat with the whole oase xyz, centered around the image 456 in message 123
  // # "xyz:a:b": A chat between a and b in the oase xyz (The participants are sorted)
  // # "xyz:a:b/m/123": A chat between a and b in the oase xyz, centered around the message 123
  // # "xyz:a:b/m/123/i/456": A chat between a and b in the oase xyz, centered around the image 456 in message 123
  //

export const isReaction = (resourceId: string) => {
  return resourceId.split("/").reverse()[1] == "r";
}

export const isEvent = (resourceId: string) => {
  return resourceId.split("/").reverse()[1] == "e";
}

export const isExpense = (resourceId: string) => {
  return resourceId.split("/").reverse()[1] == "x";
}

export const idOfResource = (resourceId: string | undefined | null) => {
  if(!resourceId) return null;
  return resourceId.split("/").reverse()[0];
}

export const resourcePath = (resourceId: string) => {
  // When given a resourceId like "a", return ""
  // When given a resourceId like "a/m/b/r/c", return "r/c"
  // When given a resourceId like "a/m/b", return "m/b"

  const parts = resourceId.split("/");
  if(parts.length < 3) return "";
  return parts.slice(-2).join("/");
}

export const context = (resourceId: string) => {
  const path = resourcePath(resourceId);
  if(path == "") return resourceId;
  return resourceId.replace("/" + path, "");
}

export const isResource = (resourceId: string) => {
  return resourcePath(resourceId) != "";
}

export const isMessage = (resourceId: string) => {
  return resourceId.split("/").reverse()[1] == "m";
}

export const isPoll = (resourceId: string) => {
  return resourceId.split("/").reverse()[1] == "p";
}

export const audience = (resourceId: string) => {
  return resourceId.split("/")[0] || "";
}

export const oaseId = (resourceId: string) => {
  return audience(resourceId)?.split(":")[0] || "";
}

export const createResourceIdFrom = (audience: string, resourceType: "event" | "expense", resourceId: string) => {
  const shortType = {
    "event": "e",
    "expense": "x",
  }[resourceType];

  return `${audience}/${shortType}/${resourceId}`;
}

export const landingPathFor = (resourceId: string) => {
  const _audience = audience(resourceId);
  const _isOneOnOne = _audience.split(":").length > 1;
  const _isResource = resourcePath(resourceId) != "";
  const _oaseId = oaseId(resourceId);

  let path = `/oase/${_oaseId}`;

  if (_isOneOnOne) {
    path = `/oase/${_oaseId}/one_on_one/${_audience}`;
  }
  if (_isResource) {
    path = `/oase/${_oaseId}/r/${resourceId}`;
  }
  return path;
}
