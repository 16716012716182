import { createContext, RefObject } from 'react'
import { MessageType } from 'app/features/oase/Message'
import { VirtualElement } from '@popperjs/core';

export type Mode = null | "reacting" | "menu" | "gif" | "reactions" | "gallery";

type MessageInteractionContext = {
  mode: Mode,
  message: RefObject<MessageType | null>,
  referenceElement: RefObject<null | VirtualElement>,
  readAndResetIfKeyboardChangeWasTriggered: () => boolean,
  setMessage: (message: MessageType, currentImageIndex?: number) => void,
  setMode: (mode: Mode, event: any, oaseId: string, chatId: string) => void,
  reset: () => void,
}

export default createContext<MessageInteractionContext>({
  mode: null,
  message: {current: null},
  referenceElement: {current: null},
  setMessage: () => {},
  setMode: () => {},
  reset: () => {},
  readAndResetIfKeyboardChangeWasTriggered: () => false
})

