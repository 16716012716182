import { View } from 'app/native'
import { useCallback, useState, useMemo } from 'react';
import Svg, { Path } from 'react-native-svg';

function calculateD(width: number, height: number, eccentricity: number, resolution: number) {
  const halfWidth = width / 2.0;
  const halfHeight = height / 2.0;

  const TWO_PI = Math.PI * 2.0;

  var d = "M " + width + " " + halfHeight;

  for (var theta = 0.0; theta <= TWO_PI + 1; theta += TWO_PI / resolution) {
    var sineTheta = Math.sin(theta);
    var cosineTheta = Math.cos(theta);
    var r = Math.pow(1 / (Math.pow(Math.abs(cosineTheta) / halfWidth, eccentricity) +
      Math.pow(Math.abs(sineTheta) / halfHeight, eccentricity)),
      1 / eccentricity);
    d += " L " + (r * cosineTheta + halfWidth) + " " + (r * sineTheta + halfHeight);
  }
  return d;
}


type Layout = {
  width: number,
  height: number,
}
type LayoutEvent = {
  nativeEvent: {
    layout: Layout,
  }
}

export const CalculatedSuperEllipse = ({ children, strokeWidth, strokeColor, fillColor, n, rounder }: { children: any, strokeWidth: number, strokeColor: string, fillColor: string, n?: number, rounder?: number }) => {
  const [layout, setLayout] = useState<Layout>();

  const path = useMemo<string>(() => {
    if (!layout) return "";
    const useRounder = rounder || 12;
    const useN = n || Math.max(layout.width, layout.height) / (useRounder || 12);
    const resolution = (layout.width + layout.height) * 2;
    return calculateD(layout.width, layout.height, useN, resolution);
  }, [Math.floor((layout?.width || 0) / 10), Math.floor((layout?.height || 0) / 10), n, rounder]);

  const onLayout = useCallback<(event: LayoutEvent) => void>((event) => {
    setLayout(event.nativeEvent.layout);
  }, []);

  return (
    <>
      <View onLayout={onLayout} style={{
        }} className={`${layout ? "web:flex-1" : "opacity-0"}`}>
        {layout ? (
          <Svg style={{
            position: 'absolute', top: 0, left: 0,
          }} width={layout.width} height={layout.height} viewBox={`-${strokeWidth / 2.0} -${strokeWidth / 2.0} ${layout.width + (strokeWidth)} ${layout.height + (strokeWidth)}`} fill="none">
            <Path d={path} stroke={strokeColor} fill={fillColor || 'transparent'} strokeWidth={strokeWidth} />
          </Svg>
        ) : null}
        <View
          style={{
            padding: strokeWidth,
          }}
        >
          {children}
        </View>
      </View>
    </>
  );
};


