import { ReactNode, useEffect, useRef, useCallback, useMemo } from 'react'
import ReaderContext from 'app/contexts/reader'
import useNotification from 'app/hooks/useNotification'
import useMainframe from 'app/hooks/useMainframe'

export const ReaderProvider = ({ children }: { children: ReactNode }) => {
  const { post } = useMainframe();

  const { unreadResources } = useNotification("");
  const readResourcesRef = useRef<string[]>([]);
  const resourcesWeAlreadyReported = useRef<string[]>([]);

  const unreadResourcesRef = useRef<string[]>([]);
  const newUnreadResources = unreadResources.filter(resource => !unreadResourcesRef.current.includes(resource));
  newUnreadResources.forEach(resource => {
    readResourcesRef.current = readResourcesRef.current.filter(r => r != resource);
    resourcesWeAlreadyReported.current = resourcesWeAlreadyReported.current.filter(r => r != resource);
  });
  unreadResourcesRef.current = unreadResources;

  const reportReadResources = useCallback(() => {
    // Call api for all the unread resources that both
    // - are in read resources, and
    // - are NOT in resourcesWeAlreadyReported

    const resourcesToReport = unreadResourcesRef.current.filter(resource => {
      return readResourcesRef.current.includes(resource) && !resourcesWeAlreadyReported.current.includes(resource);
    });
    resourcesToReport.forEach(async (resource) => {
      resourcesWeAlreadyReported.current.push(resource);
      post(`v2/notification/seen`, { resource_id: resource });
    });
  }, []);

  const markRead = useCallback((newResource: string) => {
    if (!readResourcesRef.current.includes(newResource)) {
      readResourcesRef.current.push(newResource);
      reportReadResources();
    }
  }, []);


  useEffect(() => {
    reportReadResources();
  }, [unreadResources]);

  const value = useMemo(() => ({
    markRead,
  }), [markRead]);

  return (
    <ReaderContext.Provider value={value}>
      {children}
    </ReaderContext.Provider>
  );
}


