import UserContext from 'app/contexts/user'
import { useContext } from 'react'

export function usePerson() {
  const user = useContext(UserContext)
  return user;
}

export default usePerson;

