import { IGif } from '@giphy/js-types';

type MediaTypeType = "image" | "video" | "file" | "gif" | null | undefined;

export type MediaType = {
  original_file_name?: string;
  url?: string;
  width?: number;
  height?: number;
  giphy_object?: IGif;
  type: MediaTypeType;
  mime?: string;
}

export const mediaType = (media: MediaType | null | undefined): MediaTypeType => {
  if (!media) return null;
  if (media.type) return media.type;
  if (media.giphy_object) return "gif";
  if (media.url) {
    const type = guessFileType(media.url);
    if (type) return type;
  }
  return null;
}

export const guessFileType = (uri: string): "image" | "video" | null => {
  if(!uri) return null;
  if (uri.startsWith("file://")) {
    const extension = uri.split(".").pop()?.toLowerCase() || "";
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "tiff"];
    const videoExtensions = ["mp4", "mkv", "webm", "avi", "mov", "flv"];

    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    }
  }

  if (uri.startsWith("data:")) {
    const mimeType = uri.split(";")[0]?.split(":")[1] || "";
    if (mimeType.startsWith("image/")) {
      return "image";
    } else if (mimeType.startsWith("video/")) {
      return "video";
    }
  }

  return null;
}

function giphyObject(media: MediaType | null | undefined) {
  if (!media) return null;
  if (!media.giphy_object?.images) return null;
  if (media.giphy_object?.images?.downsized_small?.mp4) return media.giphy_object?.images?.downsized_small;
  if (media.giphy_object?.images?.downsized_medium?.url) return media.giphy_object?.images?.downsized_medium;

}

export const url = (media: MediaType | null | undefined) => {
  if (!media) return "";
  return media.url || giphyObject(media)?.url || "";
}

export const mp4 = (media: MediaType | null | undefined) => {
  if (!media) return "";
  // @ts-ignore
  return (media.type == "video") ? media.url : giphyObject(media)?.mp4 || "";
}

export const width = (media: MediaType | null | undefined) => {
  if (!media) return 0;
  return media.width || giphyObject(media)?.width || 0;
}

export const height = (media: MediaType | null | undefined) => {
  if (!media) return 0;
  return media.height || giphyObject(media)?.height || 0;
}
