import { configureStore } from '@reduxjs/toolkit'
import projectionsSlice from '../slices/projections'
import subscriptionsSlice from '../slices/subscriptions'
import * as uppyReduxStore from '@uppy/store-redux'

import { persistStore, persistReducer } from 'redux-persist'
import storage from './reduxStorage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
}

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, projectionsSlice);

const store = configureStore({
  reducer: {
    projections: persistedReducer,
    subscriptions: subscriptionsSlice,
    uppy: uppyReduxStore.reducer,
  },
  // enhancers: [enhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [uppyReduxStore.STATE_UPDATE],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["register", "rehydrate", 'payload.channel', 'payload.decrypt', 'meta.arg.decrypt', 'meta.arg.method'],
        // Ignore these paths in the state
        ignoredPaths: [],
      },
    }),
})

export default store
export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
