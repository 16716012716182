import { ReactNode, useState, useEffect, useMemo, useCallback } from 'react'
import TokenContext from 'app/contexts/token'
import { getAccessToken as mainframeGetAccessToken } from 'app/lib/mainframeFetch'
import { Pressable, Text, View } from 'app/native';
import { RetryingFailure } from 'app/components/RetryingFailure';

type TokenProviderProps = {
  children: ReactNode;
};

const TokenProvider = ({ children }: TokenProviderProps) => {
  const [accessToken, setAccessToken] = useState<any>(null);
  const [tokenGettingFailed, setTokenGettingFailed] = useState(false);

  const getAccessToken = useCallback(() => {
    mainframeGetAccessToken().then((token) => {
      setTokenGettingFailed(false);
      setAccessToken(token);
    }).catch(() => {
      setTokenGettingFailed(true);
    });
  }, [mainframeGetAccessToken, setTokenGettingFailed, setAccessToken]);

  useEffect(() => {
    if (accessToken) return;

    getAccessToken();

  }, [accessToken, setTokenGettingFailed]);

  const value = useMemo(() => ({
    token: accessToken,
    setToken: setAccessToken
  }), [accessToken, setAccessToken]);

  return accessToken ? (
    <TokenContext.Provider value={value}>
      {children}
    </TokenContext.Provider>
  ) : tokenGettingFailed === true ? (
    <RetryingFailure retry={getAccessToken} />
  ) : null;
}

TokenProvider.defaultProps = {
}

export default TokenProvider

