import { createContext } from 'react'

export type ImportedKey = {
  kid: string;
  importedKey: CryptoKey;
  base64: string;
} | undefined | null;

export type OaseKeys = {
  [kid: string]: Promise<ImportedKey> | undefined | null;
}

export type EncryptionKeysMap = {
  [oaseId: string]: OaseKeys | undefined;
}

export type EncryptionKeysRepo = {
  reset: () => void;
  repo: EncryptionKeysMap;
}

export default createContext<EncryptionKeysRepo>({reset: () => {}, repo: {}})
