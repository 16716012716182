import { NavigationProvider } from './navigation'
import { SafeArea } from './safe-area'
import SocketProvider from './socket'
import { UploadProvider } from './upload'
import TokenProvider from './token'
import { EncryptionKeysProvider } from './encryption-keys'
import { AppBadgeProvider } from './app-badge'
import { ReaderProvider } from './reader'
import UserProvider from './user'
import MessageInteractionProvider from "./message-interaction";
import { Provider as ReduxProvider } from 'react-redux'
import { wsUrl } from 'app/lib/config'
import { useEffect, useMemo, useState } from 'react'
import { PersistGate } from 'redux-persist/integration/react'

import { addToLibrary } from "app/icon/library";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
// Create a client
const queryClient = new QueryClient()

import configureStore, { persistor } from './configureStore'
const store = configureStore;

import { configureUppy } from './configureUppy'
import Uppy from '@uppy/core'

export default function Provider({ children }: { children: React.ReactNode }) {
  const [uppyState, setUppyState] = useState<Uppy | null | undefined>();
  useEffect(() => {
    setUppyState(configureUppy(store));

    addToLibrary();
  }, []);

  const memoizedWsUrl = useMemo(() => wsUrl(), []);

  return (
    <SafeArea>
      <QueryClientProvider client={queryClient}>
        <EncryptionKeysProvider>
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <UploadProvider uppy={uppyState}>
                <TokenProvider>
                  <NavigationProvider>
                    <UserProvider>
                      <SocketProvider wsUrl={memoizedWsUrl}>
                        <ReaderProvider>
                          <AppBadgeProvider>
                            <MessageInteractionProvider>
                              {children}
                            </MessageInteractionProvider>
                          </AppBadgeProvider>
                        </ReaderProvider>
                      </SocketProvider>
                    </UserProvider>
                  </NavigationProvider>
                </TokenProvider>
              </UploadProvider>
            </PersistGate>
          </ReduxProvider>
        </EncryptionKeysProvider>
      </QueryClientProvider>
    </SafeArea>
  )
}
