export const refresh = () => {}
export const button = () => {}
export const warning = () => {}
export const success = () => {}

export default {
  refresh,
  button,
  warning,
  success
}
