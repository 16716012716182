import { ReactNode, useContext, useState, useMemo } from 'react'
import { View, Pressable, Text } from 'app/native'
import { useSafeArea } from 'app/provider/safe-area/use-safe-area';
import classNames from 'classnames';
import MessageInteractionContext from 'app/contexts/message-interaction';
import { usePopper } from 'react-popper';
import { Platform, useWindowDimensions } from 'react-native';

export const Menu = ({ children }: { children: ReactNode }) => {
  const safeArea = useSafeArea();
  const { referenceElement } = useContext(MessageInteractionContext);

  const [popperElement, setPopperElement] = useState(null);
  // @ts-ignore
  const { styles } = Platform.OS == "web" ? usePopper(referenceElement?.current || null, popperElement, {
    placement: 'bottom-start',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 0],
      },
    }]
  }) : {};

  const {width} = useWindowDimensions();
  const isSmall = width < 640;

  const fallbackStyles = useMemo(() => {
    return {
      paddingBottom: Math.max(10, safeArea.bottom) + 10,
    }
  }, [safeArea.bottom]);

  return <View
    ref={setPopperElement}
    style={!isSmall ? styles?.popper || fallbackStyles : fallbackStyles}
    className="w-full sm:w-auto sm:mx-auto bg-brand-black-900 shadow-sm shadow-brand-black-800 overflow-hidden rounded-t-[24px] web:sm:rounded-lg">
    {children}
  </View>
}

export const Item = ({ onPress, text, first, danger }: { onPress: (event: any) => void, text: string, first?: boolean, danger?: boolean }) => {
  return <Pressable
    className={classNames("py-5 sm:px-10 web:py-3 hover:bg-brand-black-800 border-brand-black-700", {
      "border-t-[0.5px]": !first,
      "pt-6": first,
    })}
    onPress={onPress}>
    <Text className={classNames("text-center font-t-regular text-lg", {
      "text-brand-secondary-red": danger,
      "text-white": !danger,
    })}>{text}</Text>
  </Pressable>
};
