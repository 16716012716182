import { memo, useMemo } from 'react';
import { ViewProps, ImageSourcePropType } from "react-native";
import Svg, { Path, Mask, Image } from 'react-native-svg';
import { View } from 'app/native'
import { styled } from 'nativewind'
import SuperEllipsePath from './SuperEllipsePath';
import { MotiView } from 'moti'

const StyledPath = styled(Path, { classProps: ["fill", "stroke"] });
const StyledSvg = styled(Svg);

export const SuperEllipse = memo(({ mask, bgFill, fill, stroke, scale, rotated, animationDuration, customStroke, customFill, children, imageUrl, ...props }: ViewProps & { mask?: boolean, bgFill?: string, fill?: string, stroke?: string, customStroke?: string, customFill?: string, children?: any, imageUrl?: string, rotated?: boolean, scale?: number, animationDuration?: number }) => {
  const d = SuperEllipsePath;
  const maskId = useMemo(() => `${Math.random()}`, []);
  return (
    <View {...props}>
      <MotiView
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        transition={{
          type: "timing",
          duration: animationDuration || 0,
        }}
        animate={{
          scale: scale || 1,
          transform: [{ rotate: rotated ? "45deg" : "0deg" }],
        }}
      >
        <StyledSvg
          className="w-full h-full"
          viewBox="0 0 104 104"
        >
          <Mask id={maskId} fill="black" >
            <Path d={d} fill="white" />
          </Mask>
          {(customStroke || customFill) ? (
            <Path stroke={customStroke || ""} strokeWidth="10" fill={customFill || "none"} d={d} mask={`url(#${maskId})`} />
          ) : (
            <StyledPath stroke={stroke || fill || "stroke-transparent"} fill={fill || "fill-transparent"} d={d} mask={`url(#${maskId})`} />
          )}
          {imageUrl ? (
            <Image
              href={imageUrl as ImageSourcePropType}
              width="104"
              height="104"
              preserveAspectRatio="xMidYMid slice"
              mask={`url(#${maskId})`}
            />
          ) : null}
        </StyledSvg>
      </MotiView>
      <View className="w-full h-full flex items-center justify-center">
        {children}
      </View>
    </View >
  );
});

