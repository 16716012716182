import EncryptionKeysContext, { EncryptionKeysRepo, ImportedKey } from 'app/contexts/encryptionKeys'
import { useCallback, useContext } from 'react';
import useMainframe from 'app/hooks/useMainframe'
import { Buffer } from 'buffer';
import { CipherBundle } from 'app/hooks/useEncryption';

export type EncryptionKey = {
  kid: string;
  base64: string;
}

type ProofResult = {
  jwt: string;
  key_url: string;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;


export type KeyGetter = (bundle: WithOptional<CipherBundle, 'alg' | 'ivBase64' | 'cipherBase64'>) => Promise<ImportedKey>;

async function fetchFromKMS(url: string, token: string): Promise<ImportedKey> {
  return await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${token}`
    }
  }).then(async (res) => {
    const json = await res.json() as EncryptionKey;
    const key = await window.crypto.subtle.importKey(
      "raw",
      Buffer.from(json.base64, 'base64'),
      "AES-GCM",
      false,
      ["encrypt", "decrypt"]
    );

    const result = {
      kid: json.kid,
      importedKey: key,
      base64: json.base64,
    };

    return result;
  });
}

export const getKey: KeyGetter = async (bundle) => {
  const { get } = useMainframe();
  if(bundle.proof_jwt && bundle.key_url) {
    return fetchFromKMS(bundle.key_url, bundle.proof_jwt);
  }

  return get(`v1/oases/${bundle.oaseId}/security/key_requests/${bundle.kid}`).then((res: ProofResult) => {
    const token = res.jwt;
    const url = res.key_url;
    if (token && url) {
      return fetchFromKMS(url, token);
    }
  });
}

export default function useEncryptionKeys(): KeyGetter {
  const keyBank = useContext<EncryptionKeysRepo>(EncryptionKeysContext);

  const getKeyWithKeyBank: KeyGetter = useCallback(async (bundle) => {
    if (keyBank.repo?.[bundle.oaseId]?.[bundle.kid]) {
      const keyPromise = keyBank.repo?.[bundle.oaseId]?.[bundle.kid];
      return await keyPromise;
    }

    const result = getKey(bundle);

    if (!keyBank.repo[bundle.oaseId]) {
      keyBank.repo[bundle.oaseId] = {};
    }
    // @ts-ignore
    keyBank.repo[bundle.oaseId][result.kid] = result;
    // TODO: When we get key invalidation/regeneration, we need something here
    // @ts-ignore
    keyBank.repo[bundle.oaseId][bundle.kid] = result;

    try {
      return await result;
    } catch (e) {
      // @ts-ignore
      keyBank.repo[bundle.oaseId][bundle.kid] = null;
      // @ts-ignore
      keyBank.repo[bundle.oaseId][result.kid] = null;
    }
  }, []);

  return getKeyWithKeyBank;
}

