import { Platform, useWindowDimensions } from 'react-native';
import { useState, useCallback, useEffect, memo, useRef, useContext } from 'react';
import emojiRegex from 'emoji-regex';
import { View, Text, Pressable, TextInput, TouchableOpacity } from 'app/native'
import useTranslation from 'app/hooks/useTranslation'
import useMainframe from 'app/hooks/useMainframe'
import classnames from 'classnames'
import usePerson from 'app/hooks/usePerson'
import { themeColor } from 'app/design/OaseColor'
import { AnimatePresence, MotiView } from 'moti'
import Haptics from 'app/lib/haptics'
import { useEncryption } from 'app/hooks/useEncryption'
import MessageInteractionContext from 'app/contexts/message-interaction';
import { useRouter } from 'solito/router'
import { FontAwesomeIcon } from 'app/icon';
import { usePopper } from 'react-popper';
import { SuperEllipse } from 'app/design/SuperEllipse';
import useProjection from 'app/hooks/useProjection';
import { CalculatedSuperEllipse } from 'app/components/CalculatedSuperellipse';

const Reaction = (({ oaseId, chatId, noAutoFocus }: { oaseId: string, chatId: string, noAutoFocus?: boolean }) => {
  const [oase] = useProjection(`feel:oase:${oaseId}`);
  const { message, setMode, reset, referenceElement } = useContext(MessageInteractionContext);
  const { push } = useRouter();

  const [popperElement, setPopperElement] = useState(null);
  // @ts-ignore
  const { styles } = Platform.OS == "web" ? usePopper(referenceElement.current, popperElement, {
    placement: 'bottom',
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    }]
  }) : {};

  const inputRef = useRef<any>();
  const [value, setValue] = useState("");
  const [help, setHelp] = useState(false);
  const [mounted, setMounted] = useState(false);
  const quickFix = ["❤️", "👍", "🔥", "💪", "😂"];
  const { t } = useTranslation();
  const { post } = useMainframe();
  const person = usePerson();
  const { encrypt } = useEncryption(oaseId);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    }
  }, []);

  const send = useCallback((value: string) => {
    if (!person || !encrypt || !post || !t) return;

    if (person?.ephemeral) {
      alert(t("oase.reaction.ephemeral"));
    } else {
      if (value && message.current) {
        const currentMessage = message.current;
        encrypt(value).then((bundle) => {
          return post(`v1/oases/${oaseId}/messaging/messages/${currentMessage.id}/reactions`, { reaction_cipher_bundle: bundle }).then(Haptics.success);
        }).catch((_err) => {
          alert("We had trouble sending your reaction. Please try again.");
          Haptics.warning();
        });
      }
    }
    reset();
  }, [oaseId, message, reset, post, encrypt, person, t]);

  const updateValue = useCallback((value: string) => {
    if (!send || !setValue) return;

    if (value.match(emojiRegex())) {
      send(value);
    } else {
      setValue(value);
    }
  }, [setValue, send]);

  const containerClasses = classnames("flex-1", {
    'h-screen': Platform.OS == "web",
  });

  const reactionBorderColor = themeColor("brand", "primary", "border");

  const { width } = useWindowDimensions();
  const isSmall = width < 640;

  const borderColorRaw = themeColor(oase?.item?.color, "primary", "raw", "light");

  return (
    <View key={message.current?.id} className={containerClasses}>
      <Pressable
        className="h-full flex-1 flex flex-col justify-end"
        onPress={reset}
      >
        <View
          className=""
          ref={setPopperElement}
          // @ts-ignore
          style={isSmall ? {} : styles?.popper || {}}
        >
          <View
            className="m-2 sm:w-96 sm:mx-auto bg-brand-black-50 shadow-lg rounded-lg">
            <AnimatePresence>
              {(help && !value) ? <MotiView
                from={{ top: -40, opacity: 0 }}
                animate={{ top: -60, opacity: 1 }}
                exit={{ top: -40, opacity: 0 }}
                transition={{ type: "timing", duration: 200 }}
                style={{
                  width: "100%",
                  height: 80,
                  position: "absolute",
                }}
              >
                <View className="px-4 pb-[20px] rounded-t-lg h-full flex bg-brand-primary items-center justify-center">
                  <Text className="text-sm text-brand-black-50 text-center font-t-regular">{t("oase.reaction.help")}</Text>
                </View>
              </MotiView> : null}
            </AnimatePresence>
            <View className="bg-brand-black-900 rounded-lg flex flex-row justify-around">
              {quickFix.map((emoji, i) => (
                <TouchableOpacity
                  key={i}
                  className="grow flex items-center hover:bg-brand-black-800 rounded-lg justify-center py-4"
                  onPress={() => updateValue(emoji)}>
                  <Text className="text-lg">{emoji}</Text>
                </TouchableOpacity>
              ))}
            </View>
            <Pressable
              onPress={() => {
                setHelp(!help);
                inputRef.current?.focus();
              }}
            >
              <View
                className="flex flex-row items-center justify-center py-2 ml-14 mr-10"
              >
                {!value ? <>
                  <View className="pr-1 border border-transparent py-1"><Text className="text-sm font-t-regular text-brand-black-400">{t("oase.reaction.placeholder")}</Text></View>
                  <FontAwesomeIcon icon={["fal", "circle-info"]} className={`w-5 h-5 ml-1 text-brand-black-400`} />
                </> : null}
                {value ? <View className="flex-1 flex flex-row overflow-visible items-center justify-center">
                  <View className={`shrink py-1`}>
                    <CalculatedSuperEllipse
                      strokeWidth={2}
                      rounder={14}
                      strokeColor={borderColorRaw}
                      fillColor="transparent"
                    >
                      <View className={classnames("py-1 flex flex-row items-center space-x-1", {
                        "px-2": value.length <= 2,
                        "px-3": value.length > 2 && value.length <= 20,
                        "px-4": value.length > 20 && value.length <= 30,
                        "px-5": value.length > 30,
                      })} >
                        <Text key="reaction" className={""}>{value}</Text>
                      </View>
                    </CalculatedSuperEllipse>
                  </View>
                  <TouchableOpacity
                    className="ml-2"
                    onPress={() => send(value)}
                  >
                    <SuperEllipse fill={themeColor(oase?.item?.color, "primary", "fill", "light")} className="w-7 h-7 mb-0.5">
                      <FontAwesomeIcon icon={["fas", "paper-plane-top"]} className={`w-3.5 h-3.5 -mt-0.5 ml-0.5 rotate-[-24deg] outline-none ${themeColor(oase?.item?.color, "text", "text", "primary")}`} />
                    </SuperEllipse>
                  </TouchableOpacity>
                </View> : null}
              </View>
              {!mounted ? null : <TextInput
                ref={inputRef}
                key={message.current?.id}
                returnKeyType="send"
                className={"h-0 w-0 android:h-10 android:w-3/4 opacity-0 absolute left-0"}
                autoFocus={!noAutoFocus}
                autoCorrect={false}
                autoCapitalize="none"
                placeholderTextColor="#888"
                placeholder={t("oase.reaction.placeholder") as string}
                onSubmitEditing={() => send(value)}
                onChangeText={updateValue}
                value={value}
                style={{
                  fontSize: 12
                }}
              />}
            </Pressable>
            <Pressable className="absolute right-0 bottom-0 p-2 pb-3 web:pb-3"
              onPress={(event) => {
                setMode("menu", event, oaseId, chatId);
              }}
            >
              <Text className="text-brand-black-500 font-t-black">⋯</Text>
            </Pressable>
            <Pressable className="absolute left-1 bottom-0.5 p-2 pb-3 web:pb-3"
              onPress={() => {
                const chatId = message.current?.chat_id || oaseId;
                let url: string;
                if (chatId.split("/").length > 1) {
                  url = `/oase/${oaseId}/r/${chatId}`;
                } else {
                  url = `/oase/${oaseId}/r/${message.current?.resource_id}`;
                }
                url = `${url}?shouldAutoFocus=true`;
                push(url);
                reset();
              }}
            >
              <Text className="text-brand-secondary-green font-t-regular">
                {t("oase.reaction.respond")}
              </Text>
            </Pressable>
          </View>

        </View>
      </Pressable >
    </View >
  );
});

export default memo(Reaction);
