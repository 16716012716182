const alertPolyfill = (title: string, description: string, options: any, _extra: any) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}

const alert = alertPolyfill;

export const Confirm = alert;
export default alert
