import { UserType } from "app/contexts/user";
import { useCallback } from "react";
import posthog from 'posthog-js'

export function useTracking() {
  const identify = useCallback((user: UserType) => {
    if (!user) return;
    if (user.ephemeral) return;
    posthog.identify(user.analytics_id);
  }, []);

  return { identify, reset: posthog.reset };
}

