export const appRoot = (path: string): `http:${string}` | `https:${string}` => {
  const protocol = environment() === 'production' ? `https` : 'http';
  return `${protocol}://${appDomain()}/${path}`;
}

export const mainframeRoot = (path: string) => {
  const protocol = environment() === 'production' ? `https` : 'http';
  return `${protocol}://${mainframeDomain()}/${path}`;
}

export const wsUrl = () => {
  const protocol = environment() === 'production' ? `wss` : 'ws';
  return `${protocol}://${mainframeDomain()}/socket`;
}

export const isDevelopment = () => {
  return environment() === 'development';
}

export const mainframeDomain = () => {
  // const localhost = "10.0.2.2:4000";
  // const localhost = "localhost:4000";
  // const localhost = "127.0.0.1:4000";
  // const localhost = "192.168.1.51:4000";
  const localhost = "192.168.50.185:4000";
  return environment() === 'production' ? 'api.oase.app' : localhost;
}

export const appDomain = () => {
  return environment() === 'production' ? 'oase.app' : 'localhost:3000';
}

export const localhost = () => {
  return process.env.LOCALHOST || "localhost";
}

const environment = () => {
  return process.env.NODE_ENV;
};
