import { MediaType } from "app/lib/media";
import { Pressable, Text } from "app/native";
import { useMemo } from "react";
import * as WebBrowser from 'expo-web-browser';
import { FontAwesomeIcon } from "app/icon";
import { getFontAwesomeIconFromExtension } from "app/icon/fromExtension";

export const DocumentPreview = ({ file }: { file: MediaType }) => {
  const onPress = useMemo(() => () => {
    if (file.url) {
      WebBrowser.openBrowserAsync(file.url);
    }
  }, [file]);

  const icon = useMemo(() => {
    const iconName = getFontAwesomeIconFromExtension(file.original_file_name as string);
    const classes = "w-6 h-6 text-brand-black-700";
    return <FontAwesomeIcon icon={["fal", iconName]} className={classes} />;
  }, [file]);
  return (
    <Pressable
      onPress={onPress}
      className="mt-2 ml-2 web:ml-0 web:mt-0 px-3 pt-4 pb-3 border-2 shrink border-brand-black-200 rounded-lg flex flex-col items-center justify-start"
    >
      {icon}
      <Text className="font-t-light text-center tracking-tighter text-base text-brand-black-700 mt-2">{file.original_file_name}</Text>
    </Pressable>
  )
};
