import 'raf/polyfill'
import 'setimmediate'

import Provider from 'app/provider'
import Head from 'next/head'
import React from 'react'
import Chrome from 'app/navigation/web'

import '../public/fonts/Gimlet/gimlet.css'
import '../public/fonts/Inter/inter.css'
import '../global.css'
import { AppProps } from 'next/app'
import { da } from 'app/hooks/useTranslation'

import posthog from 'posthog-js'

posthog.init('phc_Ro50ybMKIXEKpQQbtRWfRnBJYRNHmiDAUjmtxLWUAlp', { api_host: 'https://eu.i.posthog.com' })

function MyApp({ Component, pageProps, router }: AppProps) {
  const dict = router.route == "/oase/[oase_id]/join/[phrase]" ? da.common.seo.join : da.common.seo.default;
  return (
    <>
      <Head>
        <title>Oase</title>
        <meta
          name="description"
          content={dict.description}
        />
        <meta
          property="og:description"
          content={dict.description}
        />
        <meta
          property="og:title"
          content={dict.title}
        />
        <meta
          property="og:image"
          content="https://oase.app/og-image.png"
        />
        <meta
          property="og:type"
          content="website"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Provider>
        <Chrome>
          <Component {...pageProps} />
        </Chrome>
      </Provider>
    </>
  )
}

export default MyApp
