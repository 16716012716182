// @ts-nocheck
export type OaseColor = 'brand' | 'yellow' | 'red' | 'pink' | 'blue' | 'teal' | 'black' | 'white';
export type ColorName = "primary" | "accent" | "text" | "badge";
export type ColorUse = "bg" | "stroke" | "fill" | "text" | "border" | "raw";
export type ColorOn = "primary" | "accent" | "dark" | "light" | "badge";
import { Colors } from 'app/design/tailwind';

// Example usage:
// import { theme } from './OaseColor';
// Give me the primary color as tailwing bg-[] class
// theme('red', 'primary', 'bg');
// Give me the primary color as tailwing stroke-[] class
// theme('red', 'primary', 'stroke');
//
// Give me an accent color to be used on the primary color as tailwing text-[] class
// theme('red', 'accent', 'text', on: 'primary');
export function themeColor(color: OaseColor | undefined | null, name: ColorName, use: ColorUse, on: ColorOn = "primary") {
  const picked = allTheStuff[color || defaultColor()][name][on];
  if (use == "raw") {
    const split = picked.split("-");
    // Drill down into the tailwind theme using colors as root.
    // So if picked is "brand-secondary-red" we will get the value of
    // colors["brand"]["secondary"]["red"].
    // And if picked is "brand-primary" we will get the value of
    // colors["brand"]["primary"].
    return split.reduce((acc: any, cur: any) => acc[cur], Colors);
  } else {
    return `${use}-${picked}`;
  }
}

const allTheStuff = {
  "brand": {
    "primary": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "accent": {
      "primary": "brand-secondary-red",
      "light": "brand-secondary-red",
    },
    "badge": {
      "primary": "brand-secondary-red",
      "light": "brand-secondary-red",
    },
    "text": {
      "primary": "brand-black-50",
      "accent": "brand-black-50",
      "badge": "brand-black-50",
    },
  },
  "yellow": {
    "primary": {
      "primary": "brand-secondary-yellow",
      "light": "brand-secondary-yellow",
    },
    "accent": {
      "primary": "brand-secondary-blue",
      "light": "brand-secondary-blue",
    },
    "badge": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "text": {
      "primary": "brand-black-900",
      "accent": "brand-black-50",
      "badge": "brand-black-50",
    },
  },
  "red": {
    "primary": {
      "primary": "brand-secondary-red",
      "light": "brand-secondary-red",
    },
    "accent": {
      "primary": "brand-secondary-yellow",
      "light": "brand-secondary-yellow",
    },
    "badge": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "text": {
      "primary": "brand-black-50",
      "accent": "brand-black-800",
      "badge": "brand-black-50",
    },
  },
  "pink": {
    "primary": {
      "primary": "brand-secondary-pink",
      "light": "brand-secondary-pink",
    },
    "accent": {
      "primary": "brand-secondary-blue",
      "light": "brand-secondary-blue",
    },
    "badge": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "text": {
      "primary": "brand-black-900",
      "accent": "brand-black-50",
      "badge": "brand-black-50",
    },
  },
  "blue": {
    "primary": {
      "primary": "brand-secondary-blue",
      "light": "brand-secondary-blue",
    },
    "accent": {
      "primary": "brand-secondary-pink",
      "light": "brand-secondary-pink",
    },
    "badge": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "text": {
      "primary": "brand-black-50",
      "accent": "brand-black-900",
      "badge": "brand-black-50",
    },
  },
  "teal": {
    "primary": {
      "primary": "brand-secondary-teal",
      "light": "brand-secondary-teal",
    },
    "accent": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "badge": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "text": {
      "primary": "brand-black-50",
      "accent": "brand-black-50",
      "badge": "brand-black-50",
    },
  },
  "black": {
    "primary": {
      "primary": "brand-black-900",
      "light": "brand-black-900",
    },
    "accent": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "badge": {
      "primary": "brand-secondary-yellow",
      "light": "brand-primary",
    },
    "text": {
      "primary": "brand-black-50",
      "accent": "brand-black-50",
      "badge": "brand-black-50",
    },
  },
  "white": {
    "primary": {
      "primary": "white",
      "light": "brand-black-200",
    },
    "accent": {
      "primary": "brand-primary",
      "light": "brand-primary",
    },
    "badge": {
      "primary": "brand-secondary-blue",
      "light": "brand-primary",
    },
    "text": {
      "primary": "brand-black-900",
      "accent": "brand-black-50",
      "badge": "brand-black-50",
    }
  },
};

export function statusBarTheme(color: OaseColor | undefined | null) {
  switch (color || defaultColor()) {
    case 'brand':
      return 'light';
    case 'yellow':
      return 'dark';
    case 'red':
      return 'light';
    case 'pink':
      return 'dark';
    case 'blue':
      return 'light';
    case 'teal':
      return 'light';
    case 'black':
      return 'light';
    case 'white':
      return 'dark';
  }
}

export function blurTint(color: OaseColor | undefined | null) {
  switch (color || defaultColor()) {
    case 'brand':
      return 'dark';
    case 'yellow':
      return 'light';
    case 'red':
      return 'dark';
    case 'pink':
      return 'light';
    case 'blue':
      return 'dark';
    case 'teal':
      return 'dark';
    case 'black':
      return 'dark';
    case 'white':
      return 'light';
  }
}

export function defaultColor(): OaseColor {
  return 'brand';
}
