import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFileZip } from '@fortawesome/pro-light-svg-icons/faFileZip';
import { faFileCode } from '@fortawesome/pro-light-svg-icons/faFileCode';
import { faFileSpreadsheet } from '@fortawesome/pro-light-svg-icons/faFileSpreadsheet';
import { faFileLines } from '@fortawesome/pro-light-svg-icons/faFileLines';
import { faPresentationScreen } from '@fortawesome/pro-light-svg-icons/faPresentationScreen';
import { faFileAudio } from '@fortawesome/pro-light-svg-icons/faFileAudio';
import { faFileVideo } from '@fortawesome/pro-light-svg-icons/faFileVideo';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileImage } from '@fortawesome/pro-light-svg-icons/faFileImage';
import { faFiles } from '@fortawesome/pro-light-svg-icons/faFiles';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowLeftFromBracket } from '@fortawesome/pro-light-svg-icons/faArrowLeftFromBracket';
import { faFamily } from '@fortawesome/pro-light-svg-icons/faFamily';
import { faHandHoldingSeedling } from '@fortawesome/pro-light-svg-icons/faHandHoldingSeedling';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faCalendarArrowUp } from '@fortawesome/pro-light-svg-icons/faCalendarArrowUp';
import { faCalendarStar } from '@fortawesome/pro-light-svg-icons/faCalendarStar';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare';
import { faCamera } from '@fortawesome/pro-light-svg-icons/faCamera';
import { faChartSimple } from '@fortawesome/pro-light-svg-icons/faChartSimple';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import { faClipboard } from '@fortawesome/pro-light-svg-icons/faClipboard';
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faClose } from '@fortawesome/pro-light-svg-icons/faClose';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faCoins } from '@fortawesome/pro-light-svg-icons/faCoins';
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments';
import { faDoorOpen } from '@fortawesome/pro-light-svg-icons/faDoorOpen';
import { faDroplet } from '@fortawesome/pro-light-svg-icons/faDroplet';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faGif } from '@fortawesome/pro-light-svg-icons/faGif';
import { faImages } from '@fortawesome/pro-light-svg-icons/faImages';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot';
import { faMemo } from '@fortawesome/pro-light-svg-icons/faMemo';
import { faMobileButton } from '@fortawesome/pro-light-svg-icons/faMobileButton';
import { faMoneyBillSimpleWave } from '@fortawesome/pro-light-svg-icons/faMoneyBillSimpleWave';
import { faMoneyBillTransfer } from '@fortawesome/pro-light-svg-icons/faMoneyBillTransfer';
import { faPeopleGroup } from '@fortawesome/pro-light-svg-icons/faPeopleGroup';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faQrcode } from '@fortawesome/pro-light-svg-icons/faQrcode';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faReceipt } from '@fortawesome/pro-light-svg-icons/faReceipt';
import { faScaleBalanced } from '@fortawesome/pro-light-svg-icons/faScaleBalanced';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';

export const add = (library: any) => {
  library.add(
    faArrowRight,
    faArrowsRotate,
    faBan,
    faBars,
    faCalendar,
    faCalendarStar,
    faChartSimple,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faCircleInfo,
    faClock,
    faClose,
    faCog,
    faDoorOpen,
    faDroplet,
    faGif,
    faImages,
    faKey,
    faLocationDot,
    faMemo,
    faPeopleGroup,
    faPlus,
    faUser,
    faMoneyBillSimpleWave,
    faReceipt,
    faMoneyBillTransfer,
    faScaleBalanced,
    faCoins,
    faEnvelope,
    faCamera,
    faComments,
    faQuestionCircle,
    faMobileButton,
    faQrcode,
    faCalendarArrowUp,
    faClipboard,
    faClipboardCheck,
    faTimesCircle,
    faPenToSquare,
    faMagnifyingGlass,
    faPencil,
    faHandHoldingSeedling,
    faFamily,
    faArrowLeftFromBracket,
    faArrowLeft,
    faFiles,
    faFilePdf,
    faFile,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileLines,
    faFileSpreadsheet,
    faPresentationScreen,
    faFileCode,
    faFileZip
  );
}
