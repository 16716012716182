import { MediaType, url as mediaUrl, mp4 as mediaMp4, width as mediaWidth, height as mediaHeight, guessFileType, mediaType } from 'app/lib/media';
import Video from 'app/components/Video';
import { Platform } from 'react-native';
import { Image, Pressable, Text, View } from 'app/native';
import { ImageContentFit } from 'expo-image';
import { useCallback, useMemo, useState, useRef } from 'react';
import { FontAwesomeIcon } from 'app/icon';

function mimeIsVideo(mime: string | undefined) {
  return mime?.startsWith("video/");
}

export function canView(mime: string) {
  if (mime.startsWith("image/")) {
    return true;
  }
  if (mime.startsWith("video/")) {
    return true;
  }
  return false;
}

export default ({ media, src, maxHeight, maxWidth, size, setImageDimensions, alt, contentFit, className, autoPlay, muted }: { media?: MediaType, src?: string, maxWidth?: number, maxHeight?: number, alt?: string, contentFit?: ImageContentFit, className?: string, autoPlay?: boolean, muted: boolean, size?: { width: number, height: number }, setImageDimensions?: ({ width, height }: { width: number, height: number }) => void }) => {

  const player = useRef<any>(null);
  const [widthAndHeightFromLoad, setWidthAndHeightFromLoad] = useState<{ width: number, height: number } | null>(null);

  const { VideoWrapper, isVideo, showWidth, showHeight, shouldPlay, mp4, isGif, url, weKnowWidthAndHeight } = useMemo(() => {
    const type = mediaType(media);

    const url = src || mediaUrl(media);
    const mp4 = src || mediaMp4(media);
    const isGif = (type == "gif");
    const isVideo = mimeIsVideo(media?.mime) || (src ? guessFileType(src) == "video" : !!mp4);

    const width = mediaWidth(media) || widthAndHeightFromLoad?.width;
    const height = mediaHeight(media) || widthAndHeightFromLoad?.height;

    const weKnowWidthAndHeight = width && height;

    let showWidth = 0;
    let showHeight: number | undefined = 0;

    if (size) {
      showWidth = size.width;
      showHeight = size.height;
    } else {
      if (maxWidth) {
        if (weKnowWidthAndHeight) {
          const imageRatio = width / height;
          if (maxHeight) {
            // We should fit the image within the viewport
            const viewportRatio = maxWidth / maxHeight;
            if (imageRatio > viewportRatio) {
              showWidth = maxWidth;
              showHeight = maxWidth / imageRatio;
            } else {
              showHeight = maxHeight;
              showWidth = maxHeight * imageRatio;
            }
          } else {
            // We set the image width, and adjust the height accordingly
            showWidth = maxWidth;
            showHeight = maxWidth / imageRatio;
          }
        }

        if (!weKnowWidthAndHeight) {
          // We don't know the width and height of the image, so we can't calculate the aspect ratio
          // We just set the width to the max width, and let the browser calculate the height
          if (maxHeight) {
            showWidth = maxWidth;
            showHeight = maxHeight;
          } else {
            showWidth = maxWidth;
            showHeight = maxWidth;
          }
        }
      }
    }

    const shouldPlay = typeof autoPlay == "boolean" ? autoPlay : isGif;

    const VideoWrapper = autoPlay ? Pressable : View;

    if (weKnowWidthAndHeight && setImageDimensions) {
      setImageDimensions({ width, height });
    }

    return { VideoWrapper, isVideo, showWidth, showHeight, shouldPlay, mp4, isGif, url, weKnowWidthAndHeight };
  }, [media, src, maxWidth, maxHeight, widthAndHeightFromLoad]);

  const onLoad = useCallback((args: any) => {
    if (player.current) {
      player.current.seek(1);
    }
    if (weKnowWidthAndHeight) return;

    const { width, height } = args.naturalSize;
    setWidthAndHeightFromLoad({ width, height });
  }, [weKnowWidthAndHeight, player]);

  return isVideo ? (
    <VideoWrapper
      style={{
        width: showWidth,
        height: showHeight,
      }}
      className="relative"
    >
      <Video
        // @ts-ignore
        style={{
          width: showWidth,
          height: showHeight,
        }}
        ref={player}
        playInBackground
        onLoad={onLoad}
        progressUpdateInterval={10000000}
        repeat
        muted={muted}
        paused={!shouldPlay}
        ignoreSilentSwitch={muted ? "obey" : "ignore"}
        contentFit={contentFit}
        alt={alt || "Video"}
        resizeMode="cover"
        source={{ uri: mp4 || media?.url }}
        controls={autoPlay}
        className={className}
      />
      {muted && !isGif ? (
        <FontAwesomeIcon icon={["fas", "play"]} className="absolute top-1/2 left-1/2 w-16 h-16 -translate-x-8 -translate-y-8 text-white opacity-70" />
      ) : null}
    </VideoWrapper>
  ) : (showWidth && showHeight) ? (
    // @ts-ignore
    <Image
      contentPosition={"left"}
      alt={alt || "Billede"}
      src={url}
      contentFit={contentFit || (weKnowWidthAndHeight ? "contain" : "cover")}
      width={showWidth}
      height={showHeight}
      className={className}
    />
  ) : null;
}
