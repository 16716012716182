import { ReactNode, useState, useRef, useCallback, useMemo } from 'react'
import Gallery from 'app/components/Gallery';
import { KeyboardAvoidingView, Platform, Modal, useWindowDimensions } from 'react-native';
import Reaction from 'app/features/oase/Reaction'
import InteractWithMessage from 'app/features/oase/InteractWithMessage'
import InteractWithPoll from 'app/features/oase/InteractWithPoll'
import GifPicker from 'app/features/oase/GifPicker'
import { View, Pressable } from 'app/native'
import MessageInteractionContext, { Mode } from 'app/contexts/message-interaction'
import { Menu } from 'app/components/Menu';
import { MessageType } from 'app/features/oase/Message'
import { VirtualElement } from '@popperjs/core';
import virtualElement from 'app/lib/virtualElement';
import ReactionsList from 'app/features/oase/ReactionsList';
import InteractWithEvent from 'app/features/oase/InteractWithEvent';
import InteractWithExpense from 'app/features/oase/InteractWithExpense';
import InteractWithSettlement from 'app/features/oase/InteractWithSettlement';
import Media from 'app/components/Media';
import { DocumentPreview } from 'app/components/DocumentPreview';

const MessageInteractionProvider = ({ children }: { children: ReactNode }) => {
  const triggeredKeyboardChange = useRef(false);
  const chatIdRef = useRef<string | null>(null);
  const oaseIdRef = useRef<string | null>(null);
  const message = useRef<MessageType | null>(null);
  const currentImageIndex = useRef<number>();
  const referenceElement = useRef<VirtualElement | null>(null);
  const [mode, setModeState] = useState<Mode>(null);
  const { width, height } = useWindowDimensions();

  const setMode = useCallback((newMode: Mode, event: any, oaseId: null | string, chatId: null | string) => {
    referenceElement.current = event ? virtualElement(event) : null;
    chatIdRef.current = chatId;
    oaseIdRef.current = oaseId;

    if (newMode === 'reacting' || mode === 'reacting' || newMode === "gif") {
      triggeredKeyboardChange.current = true;
    } else {
      triggeredKeyboardChange.current = false;
    }
    setModeState(newMode);
  }, [mode, setModeState, triggeredKeyboardChange]);

  const setMessage = useCallback((_message: MessageType, _currentImageIndex?: number) => {
    message.current = _message;
    currentImageIndex.current = _currentImageIndex;
  }, []);

  const reset = useCallback(() => {
    setMode(null, null, null, null);
    message.current = null;
    currentImageIndex.current = undefined;
  }, [setMode]);

  const readAndResetIfKeyboardChangeWasTriggered = useCallback(() => {
    if (triggeredKeyboardChange.current) {
      triggeredKeyboardChange.current = false;
      return true;
    }
    return false;
  }, []);

  const isGiffing = useMemo(() => mode == "gif", [mode]);

  const value = useMemo(() => ({
    message,
    mode,
    referenceElement,
    readAndResetIfKeyboardChangeWasTriggered,
    setMode,
    setMessage,
    reset,
  }), [message, mode, referenceElement, readAndResetIfKeyboardChangeWasTriggered, setMode, setMessage, reset]);

  return <MessageInteractionContext.Provider value={value}>
    {children}
    {oaseIdRef.current && chatIdRef.current ? (
      <>
        <Modal
          visible={mode == "reacting"}
          animationType={Platform.OS == "web" ? "none" : "fade"}
          statusBarTranslucent
          transparent={true}
          onRequestClose={reset}
        >
          <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : "height"}
            enabled={true}
            style={{ flex: 1 }}
          >
            <Reaction
              oaseId={oaseIdRef.current}
              chatId={chatIdRef.current}
            />
          </KeyboardAvoidingView>
        </Modal>
        <Modal
          visible={mode == "menu"}
          animationType={Platform.OS == "web" ? "none" : "slide"}
          statusBarTranslucent
          transparent={true}
          onRequestClose={reset}
        >
          <View className="flex-1">
            {message.current?.type == "message" ? (
              <InteractWithMessage
                oaseId={oaseIdRef.current}
                chatId={chatIdRef.current}
              />
            ) : null}
            {message.current?.type == "poll" ? (
              <InteractWithPoll
                oaseId={oaseIdRef.current}
                chatId={chatIdRef.current}
              />
            ) : null}
            {message.current?.type == "event" ? (
              <InteractWithEvent
                oaseId={oaseIdRef.current}
                chatId={chatIdRef.current}
              />
            ) : null}
            {message.current?.type == "expense" ? (
              <InteractWithExpense
                oaseId={oaseIdRef.current}
                chatId={chatIdRef.current}
              />
            ) : null}
            {message.current?.type == "settlement" ? (
              <InteractWithSettlement
                oaseId={oaseIdRef.current}
                chatId={chatIdRef.current}
              />
            ) : null}
          </View>
        </Modal>
        <Modal
          visible={isGiffing}
          animationType={Platform.OS == "web" ? "none" : "slide"}
          statusBarTranslucent
          transparent={true}
          onRequestClose={reset}
        >
          <Pressable
            onPress={reset}
            className="h-[20vh]">
          </Pressable>
          <View
            className="bg-white rounded-t-xl shadow-lg shadow-brand-black-900 flex-1"
          >
            {chatIdRef.current ? <GifPicker
              oaseId={oaseIdRef.current}
              chatId={chatIdRef.current}
            /> : null}
          </View>
        </Modal>
        <Modal
          visible={mode == "reactions"}
          animationType={Platform.OS == "web" ? "none" : "slide"}
          statusBarTranslucent
          transparent
          onRequestClose={reset}
        >
          <View
            className="w-screen h-screen bg-transparent flex items-center flex-col"
          >
            <Pressable
              onPress={reset}
              className="grow w-full"
            >
            </Pressable>
            <Menu>
              <View className="">
                {message.current ? <ReactionsList
                  message={message.current}
                  oaseId={oaseIdRef.current}
                /> : null}
              </View>
            </Menu>
          </View>
        </Modal>
        <Modal
          visible={mode == "gallery"}
          animationType="fade"
          onRequestClose={reset}
        >
          <Pressable className="flex-1"
            onPress={reset}
          >
            {message.current ? <Gallery
              data={message.current.media?.concat(message.current.files || []) || []}
              initialIndex={currentImageIndex.current}
              renderItem={({ item, setImageDimensions }) => {
                const isVideo = item.mime?.startsWith("video");
                if (!isVideo && item.type == "file") return (
                  <View className="w-screen h-screen flex bg-white items-center justify-center">
                    <DocumentPreview file={item} />
                  </View>
                );

                return (
                  <View className="w-full h-full flex items-center justify-center">
                    <Media
                      setImageDimensions={setImageDimensions}
                      maxWidth={width}
                      maxHeight={height}
                      muted={false}
                      autoPlay
                      media={item}
                    />
                  </View>
                );
              }}
              onSwipeToClose={reset}
            /> : null}
          </Pressable>
        </Modal>

      </>
    ) : null}
  </MessageInteractionContext.Provider>
}

export default MessageInteractionProvider
