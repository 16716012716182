export function get(key: string){
  let value = localStorage.getItem(key);
  return Promise.resolve(value);
}

export function set(key: string, value: any){
  if(value === null || value === undefined){
    localStorage.removeItem(key);
    return Promise.resolve();
  }
  localStorage.setItem(key, value);
  return Promise.resolve();
}

export function clear(){
  localStorage.clear();
  return Promise.resolve();
}
