import TokenContext from 'app/contexts/token'
import { useContext, useMemo } from 'react';

export function useToken(): [any, (token: any) => void] {
  const { token, setToken } = useContext(TokenContext);
  const value = useMemo(() => [token, setToken], [token, setToken]);

  // @ts-ignore
  return value;
}

