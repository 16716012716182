import ImageGallery from 'react-image-gallery';

const Gallery = ({ data, initialIndex, onSwipeToClose, renderItem }: { data: string[], initialIndex: number, onSwipeToClose: () => void, renderItem: any }) => {

  return (
    <div className="flex-1 bg-black flex items-center">
      <div className="w-full">
        <ImageGallery
          showPlayButton={false}
          showFullscreenButton={false}
          onClick={onSwipeToClose}
          startIndex={initialIndex}
          showNav={false}
          showThumbnails={false}
          items={data}
          renderItem={(item: any) => renderItem({ item, setImageDimensions: (dimensions: any) => {} })}
        />
      </div>
    </div>
  );
}

export default Gallery;

