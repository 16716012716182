import { useCallback, useMemo, useRef, useState } from 'react';
import EncryptionKeysContext, {EncryptionKeysMap} from 'app/contexts/encryptionKeys';

export const EncryptionKeysProvider = ({ children }: {children: any}) => {
  const keyBank = useRef<EncryptionKeysMap>({});
  const [resetCount, setResetCount] = useState(0);

  const reset = useCallback(() => {
    keyBank.current = {};
    setResetCount(resetCount + 1);
  }, [keyBank, resetCount]);

  const result = useMemo(() => ({
    reset,
    repo: keyBank.current,
  }), [reset, keyBank, resetCount]);

  return (
    <EncryptionKeysContext.Provider value={result}>
      {children}
    </EncryptionKeysContext.Provider>
  );
};
