import { createSlice } from '@reduxjs/toolkit'

export type SubscriptionsCountType = {
  channel: string;
  subscriberId: string;
  priority: boolean;
}

export type CountsState = SubscriptionsCountType[];

export type SubscriptionsSliceState = {
  subscribers: CountsState;
}

const initialState: SubscriptionsSliceState = {
  subscribers: [],
};

const subscriberSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    reset: () => initialState,
    subscriberAdded(state, reduxAction) {
      const { channelTopic, priority, subscriberId } = reduxAction.payload;
      let countState: SubscriptionsCountType = { channel: channelTopic, subscriberId, priority };

      const existingIndex = state.subscribers.findIndex((s) => s.subscriberId == subscriberId && s.channel == channelTopic);
      if (existingIndex >= 0) {
        return state;
      }

      state.subscribers.push(countState);
      return state;
    },
    subscriberRemoved(state, reduxAction) {
      const { channelTopic, subscriberId } = reduxAction.payload;
      const existingCount = state.subscribers.find((s) => s.channel == channelTopic && s.subscriberId == subscriberId);
      if (existingCount) {
        // Remove this from the array
        state.subscribers = state.subscribers.filter((s) => s.channel != channelTopic || s.subscriberId != subscriberId);
      }
      return state;
    },
  }
})

export const { subscriberAdded, subscriberRemoved, reset } = subscriberSlice.actions
export default subscriberSlice.reducer

