import { useEffect, useMemo } from "react";

export default ({ repeat, style, muted, source, paused, controls }: {
  repeat?: boolean,
  style?: any,
  muted?: boolean,
  playInBackground?: boolean,
  progressUpdateInterval?: number,
  resizeMode?: string,
  source: { uri: string },
  paused?: boolean,
  controls?: boolean,
  onLoad?: (args: any) => void,
}) => {

  const id = useMemo(() => Math.random().toString(), []);

  const isStream = useMemo(() => {
    return source.uri.endsWith("m3u8");
  }, [source]);

  useEffect(() => {
    if(isStream) {
      const vid = document.getElementById(id);
      // @ts-ignore
      videojs(vid);
    }
  }, [id, isStream]);

  return (
    <video
      id={id}
      style={style}
      loop={repeat}
      muted={muted}
      controls={controls}
      src={isStream ? undefined : source.uri}
      autoPlay={!paused}
    >
      { isStream ? (
        <source
          src={source.uri}
          type="application/x-mpegURL"
        />
      ) : null }
    </video>
  );
}
