import { useEffect, useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMore, ProjectionDataType, ProjectionSliceState, resetProjection, startSession } from 'app/slices/projections'
import { subscriberRemoved, subscriberAdded } from 'app/slices/subscriptions'
import SocketContext from 'app/contexts/socket'

type GetMoreType = () => void;

type OptionsType = {
  priority?: boolean;
  paginate?: boolean;
  session?: boolean;
  reset?: boolean;
}

const useProjection = <T = any>(
  channelTopic: string,
  options: OptionsType = { priority: false, paginate: true, session: false, reset: false }
): [ProjectionDataType<T> | undefined, GetMoreType] => {
  if (channelTopic.endsWith("undefined")) {
    throw new Error("Channel topic ends with undefined");
  }

  const mergedOptions = useMemo(() => {
    const defaultOptions = { priority: false, paginate: true };
    return { ...defaultOptions, ...options };
  }, [options]);

  const subscriberId = useMemo(() => Math.random().toString(36).substring(2), []);

  const { channels } = useContext(SocketContext);
  const dispatch = useDispatch();


  useEffect(() => {
    if (channelTopic == "") {
      return;
    }
    if (options?.session) {
      dispatch(startSession({ channelTopic }))
    }
    if (options?.reset) {
      dispatch(resetProjection({ channelTopic }))
    }
    dispatch(subscriberRemoved({ channelTopic, subscriberId }));
    dispatch(subscriberAdded({ channelTopic, subscriberId, priority: mergedOptions.priority }))
    return () => {
      dispatch(subscriberRemoved({ channelTopic, subscriberId }));
    }
  }, [channelTopic, mergedOptions.priority, options?.session, options?.reset, subscriberId]);

  const selector = useCallback((state: { projections: ProjectionSliceState }) => {
    if (channelTopic == "") {
      return;
    }

    if (options?.session) {
      const result =  state.projections.sessions[channelTopic] || { loading: true };
      return result;
    } else if(options?.reset) {
      const result =  state.projections.data[channelTopic] || { loading: true };
      if(result.connecting) {
        return { loading: true };
      }
      return result;
    } else {
      return state.projections.data[channelTopic] || { loading: true };
    }
  }, [channelTopic, options?.session]);
  const projection = useSelector(selector, (left, right) => {
    if(left?.version && right?.version && left.version == right.version && left.head == right.head && left.tail == right.tail) {
      // This is if you're having items, and the version is the same.
      return true;
    }

    return false;
  });


  const moreCallback = useCallback(() => {
    const channel = channels.current[channelTopic];
    if (channel) {
      dispatch(getMore({ channelTopic, channel }));
    } else {
      console.error(`Channel ${channelTopic} not found in channels: ${Object.keys(channels)}}`);
    }
  }, [channelTopic]);

  const value = useMemo<[ProjectionDataType<T> | undefined, GetMoreType]>(() => {
    return [projection, moreCallback];
  }, [projection, moreCallback]);

  return value;
};

export default useProjection
