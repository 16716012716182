import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';
import 'app/lib/EventSource';
import ReduxStore from '@uppy/store-redux'

export const configureUppy = (store: any) => {
  const uppy = new Uppy({
    id: 'global',
    autoProceed: true,
    store: new ReduxStore({ store, id: 'global' }),
    debug: false,
  }).use(Transloadit, {
    assemblyOptions: {
      params: {
        auth: { key: '465efc1e69184d3291b2fddfa1d02fdd' },
        template_id: '0c25c232d1964e999b339966da6c7986',
      },
    },
  });

  return uppy;
}
