import { useAppState } from '@react-native-community/hooks';
import { Pressable, View, Text } from 'app/native';
import { useEffect, useRef, useState } from 'react';
import Loading from './Loading';
import NetInfo from '@react-native-community/netinfo';

export const RetryingFailure = ({ retry }: { retry?: () => void }) => {
  const timeout = useRef<any>();
  const appState = useAppState();
  const [isConnected, setIsConnected] = useState<boolean | null>(true);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected);
      if (retry) retry();
    });

    // To unsubscribe to these update, just use:
    return unsubscribe;
  }, [retry, setIsConnected]);

  useEffect(() => {
    if (appState !== 'active') return;

    if (retry) {
      timeout.current = setInterval(retry, 3000);
    }

    return () => clearInterval(timeout.current);
  }, [appState]);

  return (
    <Pressable onPress={retry} className="flex-1 w-full h-full flex items-center justify-center">
      <View className="h-10 w-10">
        <Loading />
      </View>

      <View className="mt-4">
        <Text className="text-brand-black-700 font-t-bold">
          {isConnected === false ? (
            "Det ser ikke ud til at du har internetforbindelse..."
          ) : " "}
        </Text>
      </View>
    </Pressable>
  );
};
