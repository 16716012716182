import { Player } from '@lottiefiles/react-lottie-player';

export default ({size}: {size?: number}) => {
  const width = size || 40;
  return <Player
    autoplay
    loop
    src="/lottie/loading.json"
    style={{ height: `${width}px`, width: `${width}px` }}
  />;
};
