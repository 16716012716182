import { useCallback, useEffect, useState } from 'react';
import useProjection from 'app/hooks/useProjection'
import usePerson from 'app/hooks/usePerson'
import { isEvent, isPoll } from 'app/lib/resourceId';

export type NotificationState = {
  unreadCount: number;
  unreadResources: string[];
};

type Options = {
  butNotPrefix?: string,
  session?: boolean,
}

export function onlyOneOnOn(unreadResources: string[], oaseId: string): string[] {
  return unreadResources.filter((resourceId: string) => {
    return !resourceId.startsWith(`${oaseId}/`);
  });
}

export function onlyPolls(unreadResources: string[]): string[] {
  return unreadResources.filter((resourceId: string) => {
    return isPoll(resourceId);
  });
}

export function onlyEvents(unreadResources: string[]): string[] {
  return unreadResources.filter((resourceId: string) => {
    return isEvent(resourceId);
  });
}

export default function useNotification(prefix: string | undefined, options: Options = {}): NotificationState {
  const user = usePerson();

  const calculate = useCallback((unread: any) => {
    if (!unread?.collection || (!prefix && prefix != "")) return;

    const unreadResources: string[] = unread.collection.reduce((list: string[], resourceId: string) => {
      let matcher = resourceId.startsWith(prefix);
      if (options?.butNotPrefix) matcher = matcher && !resourceId.startsWith(options.butNotPrefix);
      if (matcher) {
        return list.concat(resourceId);
      }
      return list;
    }, []);

    return { unreadCount: unreadResources.length, unreadResources };
  }, [prefix, options]);

  const [unread] = useProjection(user ? `notification:unread:v2:${user?.id}` : "", { priority: true, session: options?.session });
  const [state, setState] = useState<NotificationState>(calculate(unread) || { unreadCount: 0, unreadResources: [] });

  useEffect(() => {
    const newState = calculate(unread);
    if (newState) setState(newState);
  }, [unread]);

  return state;
};
