import { useState, useEffect, ReactNode, useContext, useRef, useCallback, useMemo, memo } from 'react'
import { AppState } from 'react-native'
import PropTypes from 'prop-types'
import { Socket, Channel } from 'phoenix'
import { refreshAccessToken } from 'app/lib/mainframeFetch'
import { AppDispatch } from 'app/provider/configureStore'

import SocketContext from 'app/contexts/socket'
import TokenContext from 'app/contexts/token'

// import { captureMessage } from 'app/lib/Sentry';

import { messageReceived, ReplaceType, pullProjection, replaceProjection, setConnectionStatus, joining, joined, replaceProjectionIfVersionIsDifferent, EvolveInfo, storeEvolveInfo, evolve, checkIntegrity } from 'app/slices/projections'
import { SubscriptionsSliceState } from 'app/slices/subscriptions'
import { useDispatch, useSelector } from 'react-redux'

import { Decrypter, useEncryption } from 'app/hooks/useEncryption'
import { isDevelopment } from 'app/lib/config'

type ChannelJoinResponseFromMainframe = {
  reason: "not found" | "not allowed" | "no session";
}
type ErrorContext = {
  channel?: Channel;
  socket?: Socket;
  situation: 'join' | 'message' | 'connect';
};
type ErrorCallback = (context: ErrorContext, error: ChannelJoinResponseFromMainframe | Event | string | number) => void;

type SocketProviderProps = {
  wsUrl: string;
  children: ReactNode;
};

type ChannelCount = {
  count: number;
  priority: boolean;
};

const SocketProvider = ({ wsUrl, children }: SocketProviderProps) => {
  const channelsRef = useRef<{ [key: string]: any }>({});
  const { decrypt } = useEncryption();
  const [socket, setSocket] = useState<any>()
  const [appIsInForeground, setAppIsInForeground] = useState(true);
  const { token, setToken } = useContext(TokenContext);
  const dispatch = useDispatch();
  const selector = useCallback((state: { subscriptions: SubscriptionsSliceState }) => state.subscriptions.subscribers, []);
  const subscriberCounts = useSelector(selector);
  const channelCounts: { [key: string]: ChannelCount; } = useMemo(() => {
    const result: {[key: string]: ChannelCount} = {};
    subscriberCounts.forEach((sub) => {
      const res = result[sub.channel] || {} as ChannelCount;
      res.count = res.count || 0;
      res.count += 1;
      res.priority = res.priority || sub.priority;

      result[sub.channel] = res;
    });
    return result;
  }, [subscriberCounts]);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (nextAppState == "active") {
        setAppIsInForeground(true);
      } else if (nextAppState == "background") {
        setAppIsInForeground(false);
      }
    });

    return () => {
      subscription.remove();
    };
  }, [setAppIsInForeground]);

  const onError = useCallback<ErrorCallback>(async (context, error) => {
    if (context.situation == "join" || (error as ChannelJoinResponseFromMainframe).reason == "no session") {
      const newToken = await refreshAccessToken();
      if (newToken) {
        setToken(newToken);
      }
    } else {
      if (isDevelopment()) {
        if (context.socket) {
          console.error("🔥 Socket error", JSON.stringify(error, null, 2));
        }
      }
      // captureMessage(JSON.stringify(error));
    }
  }, [setToken]);

  useEffect(() => {
    let newSocket: any = null;
    if (token && appIsInForeground) {
      newSocket = new Socket(wsUrl, { params: { access_token: token } })
      dispatch(setConnectionStatus({ status: "connecting" }));
      newSocket.onError(function(error: Event | string | number) {
        onError({ socket: newSocket, situation: 'connect' }, error);
      })
      newSocket.onOpen(function() {
        // console.log("🎤 Socket opened");
        setSocket(newSocket);
        dispatch(setConnectionStatus({ status: "connected" }));
      })
      newSocket.onClose(function() {
        // console.log("🚪 Socket closed");
      });
      newSocket.connect();
    }
    return () => {
      if (newSocket) {
        setSocket(null);
        newSocket.disconnect();
        channelsRef.current = {};
      }
    };
  }, [wsUrl, token, onError, appIsInForeground]);

  useEffect(() => {
    if (!socket) return;
    if (!dispatch) return;

    function sync(runForPriority: boolean) {
      const allKeys = Object.keys(channelCounts).concat(Object.keys(channelsRef.current));
      return allKeys.map(function(channelTopic: string) {
        new Promise((resolve, _reject) => {
          const { count, priority } = channelCounts[channelTopic] || { count: 0, priority: true };
          if (priority != runForPriority) return resolve("OK");

          if (count && count > 0 && !channelsRef.current[channelTopic]) {
            channelsRef.current[channelTopic] = joinChannel(socket, channelTopic, dispatch, decrypt, onError);
          } else if (count === 0 && channelsRef.current[channelTopic]) {
            dispatch(joining({ channelTopic }));
            channelsRef.current[channelTopic]?.leave();
            channelsRef.current[channelTopic] = null;
          }
          resolve("OK");
        });
      });
    }

    // console.log("🔌 Syncing channels");
    Promise.all(sync(true)).then(() => setTimeout(() => sync(false), 1000));
  }, [channelCounts, socket, dispatch, onError, channelsRef, decrypt]);

  const value = useMemo(() => ({ socket, channels: channelsRef }), [socket, channelsRef]);

  return (
    <SocketContext.Provider value={value}>
      {children}
    </SocketContext.Provider>
  )
}

const joinChannel = (socket: any, channelTopic: string, dispatch: AppDispatch, decrypt: Decrypter, onError: ErrorCallback) => {
  if (!socket) return () => { };

  const channel = socket.channel(channelTopic, { client: 'browser' });

  channel.onMessage = (incommingEvent: string, incommingPayload: any) => {
    let event = incommingEvent;
    let payload = incommingPayload;

    if (incommingEvent === 'phx_reply') {
      event = `projection.do.${incommingPayload.status}`;
      payload = incommingPayload.response;
    }

    switch (event) {
      case 'projection.ask.evolve':
        dispatch(evolve({ channelTopic, decrypt }));
        break;
      case 'projection.ask.replace':
        dispatch(replaceProjection({ event, payload, channelTopic, channel }));
        break;

      case 'projection.ask.pull':
        dispatch(pullProjection({ event, payload, channelTopic, channel }));
        break;

      case 'projection.do.replace':
      case 'projection.do.head':
      case 'projection.do.patch':
      case 'projection.do.tail':
        dispatch(messageReceived({ event, payload, channelTopic, decrypt }));
        break;

      case 'phx_error':
        onError({ channel, situation: 'message' }, `Channel message error: ${JSON.stringify(incommingEvent)} ${JSON.stringify(incommingPayload)}`);
        break;

      default:
        break;
    };

    // We must do this to prevent the channel from closing
    return incommingPayload;
  }

  // channel.onClose(() => console.log(`🚪 Channel closed ${channelTopic}`));
  // channel.onError((error) => console.log(`💥 Channel error ${channelTopic}: ${JSON.stringify(error)}`));

  dispatch(joining({ channelTopic }));
  channel.join()
    .receive("error", (err: any) => {
      onError({ channel, situation: 'join' }, err);
    })
    .receive("ok", async (res: ReplaceType | EvolveInfo) => {
      // console.log(`🎤 Channel joined ${channelTopic}`);
      if ('evolve_url' in res) {
        dispatch(storeEvolveInfo({ channelTopic, evolveInfo: res }));
        await dispatch(evolve({ channelTopic, decrypt }));
        const integrityResponse = await dispatch(checkIntegrity({ channelTopic }));
        if (integrityResponse.meta.requestStatus === 'rejected') {
          dispatch(evolve({ channelTopic, decrypt }));
        }
      } else {
        if (res.item || res.collection) {
          dispatch(messageReceived({ event: 'projection.do.join', payload: res, channelTopic, decrypt }));
        }
        if (res.version && !res.item && !res.collection) {
          dispatch(replaceProjectionIfVersionIsDifferent({ channelTopic, channel, newVersion: res.version }));
        } else {
          // 2023-08-23: I commented this out, as it seems weird to ask for a replace, when we already have the fresh collection
          // 2023-08-31: We actually need that replace, because the collection might have changed in the older parts, that
          // might not be in the new collection from the join.
          if ((!res.item && !res.collection) || res.has_more) {
            // If we got a collection from the join, we need to ask for a replace
            // or
            // if we got nothing.
            // I.e. if there is no item, we need to ask for a replace
            dispatch(replaceProjection({ channelTopic, channel }));
          }
        }
      }

      dispatch(joined({ channelTopic }));
      res
    })

  return channel;
}

SocketProvider.defaultProps = {
}

SocketProvider.propTypes = {
  wsUrl: PropTypes.string.isRequired,
}

export default memo(SocketProvider)
