import { useState, useEffect, ReactNode, useCallback } from 'react'
import { Platform } from 'react-native'
import UserContext, { UserType } from 'app/contexts/user'
import { useToken } from 'app/hooks/useToken'
import useMainframe from 'app/hooks/useMainframe'
import useTranslation from 'app/hooks/useTranslation'
import { usePush } from '../push'
import { useTracking } from 'app/hooks/useTracking'
import { RetryingFailure } from 'app/components/RetryingFailure'

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [accessToken, _setToken] = useToken();
  const [user, setUser] = useState<UserType>(null);
  const mainframe = useMainframe();
  const { t } = useTranslation();
  const [expoToken, deviceToken] = usePush();
  const tracking = useTracking();
  const [fetchingFailed, setFetchingFailed] = useState(false);

  useEffect(() => {
    if (!user) return;

    tracking.identify(user);
  }, [tracking, user?.analytics_id]);

  const getUser = useCallback(() => {
    if (!accessToken) return setUser(null);

    mainframe.get('v1/iam/me').then((user) => {
      if (!user) alert(t('provider.user.fetchFailed') + "a");
      setFetchingFailed(false);
      setUser(user);
    }).catch(() => {
      setFetchingFailed(true);
      // We might get interrupted by a redirect when auth on web.
    });
  }, [accessToken, setUser, mainframe, t, setFetchingFailed]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    if (!accessToken || !deviceToken) return;

    mainframe.post(`v1/notification/push`, { expo_token: expoToken, device_token: deviceToken, platform: Platform.OS })
  }, [accessToken, expoToken, deviceToken]);

  return !!user ? (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  ) : (fetchingFailed ? (
    <RetryingFailure retry={getUser} />
  ) : null);
}

UserProvider.defaultProps = {
  options: {}
}

export default UserProvider
