import { VirtualElement } from "@popperjs/core";

export default (event: any): VirtualElement => {
  return {
    getBoundingClientRect: () => ({
      toJSON: () => null,
      x: event.nativeEvent.pageX,
      y: event.nativeEvent.pageY,
      top: event.nativeEvent.pageY,
      left: event.nativeEvent.pageX,
      bottom: event.nativeEvent.pageY,
      right: event.nativeEvent.pageX,
      width: 0,
      height: 0
    })
  };
}
