export default function Example({ children }: { children: any }) {
  return (
    <>
      <main className="flex-1">
        {children}
      </main>
    </>
  )
}

