import { ImportedKey } from "app/contexts/encryptionKeys";
import { CipherBundle } from "app/hooks/useEncryption";
import { Buffer } from 'buffer';

export default async (bundle: CipherBundle, key: ImportedKey): Promise<string> => {
  if (!key) return "";

  const iv = Buffer.from(bundle.ivBase64, 'base64');
  const cipher = Buffer.from(bundle.cipherBase64, 'base64');

  let decrypted: null | ArrayBuffer = null;
  try {
    decrypted = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv
      },
      key.importedKey,
      cipher
    );
  } catch (e) {
  }

  if(!decrypted) {
    return "";
  }

  const res = Buffer.from(decrypted).toString('utf8');
  return res;
}
