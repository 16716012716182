import { createContext, MutableRefObject } from 'react'
import { Channel } from 'phoenix'

type SocketAndChannels = {
  socket: any;
  channels: MutableRefObject<{ [key: string]: Channel; }>;
}

const SocketContext = createContext<SocketAndChannels>({socket: null, channels: {current: {}}})
export default SocketContext
