const fontAwesomeIconClasses = {
  // Media
  "png": "file-image",
  "jpg": "file-image",
  "jpeg": "file-image",
  "gif": "file-image",
  "mp3": "file-audio",
  "mpg": "file-video",
  "mpeg": "file-video",
  "mp4": "file-video",
  // Documents
  "pdf": "file-pdf",
  "pages": "file-lines",
  "doc": "file-lines",
  "docx": "file-lines",
  "odt": "file-lines",
  "xls": "file-spreadsheet",
  "numbers": "file-spreadsheet",
  "xlsx": "file-spreadsheet",
  "ods": "file-spreadsheet",
  "ppt": "presentation-screen",
  "pptx": "presentation-screen",
  "key": "presentation-screen",
  "odp": "presentation-screen",
  "txt": "file-lines",
  "htm": "file-code",
  "html": "file-code",
  "json": "file-code",
  // Archives
  "gzip": "file-zip",
  "zip": "file-zip",
  // Unknown
  "unknown": "file",
}

function isValidString(o: any) {
  if (o === null) {
    return false;
  }
  if (
    typeof o == "string" ||
    (typeof o == "object" && o.constructor === String)
  ) {
    return o.length > 0;
  } else {
    return false;
  }
}

export function getFontAwesomeIconFromExtension(filename: string) {
  const extension = filename.split('.').pop() || "unknown";
  // @ts-ignore
  const result = fontAwesomeIconClasses[extension];
  if (isValidString(result)) { return result }
  else { return "file" }
}

