import Uppy from '@uppy/core'
import UploadContext, { FileAdder } from 'app/contexts/upload'
import { useCallback, useMemo } from 'react';
import { ImagePickerAsset } from 'expo-image-picker';
import { DocumentPickerAsset } from 'expo-document-picker';

const fetchImageFromUri = async (uri: any) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};

export const UploadProvider = ({ children, uppy }: { children: React.ReactNode, uppy: Uppy | null | undefined }) => {
  const addFile = useCallback<FileAdder>(async (asset) => {
    if(!uppy) return null;

    const blob = await fetchImageFromUri(asset.uri);

    function doAdd(uppy: Uppy) {
      const originalFileName = (asset as ImagePickerAsset).fileName || (asset as DocumentPickerAsset).name || undefined;

      return uppy.addFile({
        name: originalFileName,
        data: blob,
        type: asset.mimeType,
        meta: {
          uri: asset.uri,
        }
      });
    }

    try {
      return doAdd(uppy);
    } catch (e: any) {
      if(e.message.includes("duplicate file")) {
        uppy.cancelAll();
        return doAdd(uppy);
      } else {
        throw e;
      }
    }
  }, [uppy])

  const value = useMemo(() => ({ uppy, addFile }), [uppy, addFile]);

  return (
    <UploadContext.Provider value={value}>
      {children}
    </UploadContext.Provider>
  )
}
