import { memo, useCallback, useRef, useEffect, useState, useContext, useMemo } from "react";
import useMainframe from 'app/hooks/useMainframe'
import { useEncryption } from 'app/hooks/useEncryption';
import { Platform, useWindowDimensions } from "react-native";
import { View, TextInput, Pressable, Image } from 'app/native'
import useTranslation from 'app/hooks/useTranslation'
import { MasonryFlashList as FlashList } from "@shopify/flash-list";
import MessageInteractionContext from 'app/contexts/message-interaction'
import { MessageType } from './Message';
import useParticipant from 'app/hooks/useParticipant'
import Haptics from 'app/lib/haptics'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'app/provider/configureStore'
import { mutate } from 'app/slices/projections';
import { GiphyFetch, GifsResult } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import classNames from "classnames";
import Video from 'app/components/Video';
import { Colors } from "app/design/tailwind";
const API_KEY = "n3xtklWBfACziUQuys1NvJdyNr2LPKOv";
const gf = new GiphyFetch(API_KEY)

export default memo(({ oaseId, chatId }: { oaseId: string, chatId: string }) => {
  const [mounted, setMounted] = useState(false);
  const isSubmitting = useRef(false);
  const dispatch: AppDispatch = useDispatch();
  const { encrypt } = useEncryption(oaseId);
  const { reset } = useContext(MessageInteractionContext);
  const { t } = useTranslation();
  const placeholder = t("oase.gifPicker.search");
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const query = useRef<string | null>(null);
  const [gifs, setGifs] = useState<GifsResult>();
  const participant = useParticipant();
  const { post } = useMainframe();

  useEffect(() => {
    gf.trending({ limit: 50, rating: 'g' }).then((res) => {
      setGifs(res);
    });

    setTimeout(() => {
      setMounted(true);
    }, 100);
    return () => {
      setMounted(false);
    }
  }, []);

  const submit = useCallback(async (gif: IGif) => {
    if (isSubmitting.current) return;
    isSubmitting.current = true;

    const json = JSON.stringify(gif);
    const giphyObjectCipherBundle = await encrypt(json);

    const optimistic: MessageType = {
      type: "message",
      optimistic: true,
      version: "",
      temp_id: `temp_${Math.random()}`,
      created_at: new Date().toISOString(),
      participant_id: participant?.id || "",
      body: "",
      body_type: 'markdown',
      chat_id: chatId,
      media: [{
        type: "gif",
        giphy_object: gif,
      }]
    }
    const params = {
      chat_id: chatId,
      body_cipher_bundle: null,
      body_type: 'markdown',
      media: [{
        giphy_object_cipher_bundle: giphyObjectCipherBundle,
      }]
    }
    const path = `v1/oases/${oaseId}/messaging/messages`;
    const result = dispatch(mutate({
      method: post, path, params, optimistic: {
        projection: `evolving:messaging:chat:${chatId}`,
        action: "optimistic.append",
        item: optimistic,
      }
    }));
    result.unwrap().then(() => {
      Haptics.success();
    }).catch(() => {
      Haptics.warning();
      alert("Failed to send message");
    });
    reset();
  }, [chatId]);

  const onChange = useCallback((text: string) => {
    query.current = text;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      search();
    }, 1000);
  }, []);

  const search = useCallback(() => {
    if (!query.current) {
      gf.trending({ limit: 50, rating: 'g' }).then((res) => {
        setGifs(res);
      });
      return;
    }
    gf.search(query.current, { limit: 50, rating: 'g' }).then((res) => {
      setGifs(res);
    });
  }, []);

  const overrideItemLayout = useCallback((
    layout: { span?: number; size?: number },
    item: IGif
  ): void => {
    layout.size = item.images.fixed_width.height;
  }, []);

  const { width } = useWindowDimensions();
  const numColumns = useMemo(() => {
    return Math.ceil(width / 200);
  }, [width]);

  const itemWidth = useMemo(() => {
    return width / numColumns;
  }, [width, numColumns]);

  const renderItem = useCallback(({ item }: { item: IGif, index: number }) => {
    const gif = item;
    const ratio = gif.images.fixed_width_small.height / gif.images.fixed_width_small.width;
    const height = itemWidth * ratio;
    return (
      <Pressable
        onPress={() => {
          submit(gif);
        }}
        className={classNames("", {})}>
        <Image
          style={{
            width: itemWidth,
            height: height,
          }}
          // @ts-ignore
          unoptimized
          resizeMode="cover"
          alt={gif.title}
          width={gif.images.fixed_width_small.width}
          height={gif.images.fixed_width_small.height}
          src={gif.images.fixed_width_small?.url || gif.images.preview?.url || ""}
        />
      </Pressable>
    );
  }, [itemWidth, submit]);


  return (
    <View className="flex-1">
      {!mounted ? null : <TextInput
        className="px-2 mx-2 my-4 py-3 font-t-regular border shadow border-brand-black-100 rounded-lg bg-white"
        placeholder={placeholder}
        autoCapitalize="none"
        autoFocus={Platform.OS === "web"}
        placeholderTextColor={Colors.brand.black[800]}
        style={{
        }}
        onChangeText={onChange}
      />}
      <FlashList
        numColumns={numColumns}
        estimatedItemSize={200}
        overrideItemLayout={overrideItemLayout}
        keyboardShouldPersistTaps="handled"
        keyExtractor={(item) => item.id as string}
        // @ts-ignore
        renderItem={renderItem}
        // @ts-ignore
        data={gifs?.data || []}
        keyboardDismissMode="on-drag"
      />
    </View>
  );
});
